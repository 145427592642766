const removeEmptyElements = obj => {
  let retunObj = {}

  Object.keys(obj).forEach(key => {
    if (obj[key] && typeof obj[key] === 'object' && !Array.isArray(obj[key])) {
      const nestedObj = removeEmptyElements(obj[key])

      if (Object.keys(nestedObj).length) {
        retunObj[key] = nestedObj
      }
    } else if (obj[key] !== '' && obj[key] !== undefined && obj[key] !== 'undefined' && obj[key] !== null && obj[key] !== 'null') {
      retunObj[key] = obj[key]
    }
  })
}

export default {
  removeEmptyElements
}
