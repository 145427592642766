import advertisingMeta from './advertisingMeta'
import productMeta from './productMeta'
import globalRetailMeta from './globalRetailMeta'
import librariesManageMeta from './librariesManageMeta'
import salesMeta from './salesMeta'
import sampleLibraryMeta from './sampleLibraryMeta'
import ordersPromoMeta from './ordersPromoMeta'
import ordersSampleMeta from './ordersSampleMeta'

export {
  advertisingMeta,
  globalRetailMeta,
  librariesManageMeta,
  productMeta,
  salesMeta,
  sampleLibraryMeta,
  ordersPromoMeta,
  ordersSampleMeta
}
